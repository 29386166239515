<template>
  <div>
    <ds-header title="添加权益"></ds-header>
    <div>
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="权益类型" prop="benefitType">
          <a-select  @change="benefitTypeChange" v-model="form.benefitType" :disabled="isView" placeholder="请选择权益类型">
            <a-select-option :value="1">
              固定权益
            </a-select-option>
            <a-select-option :value="2">
              临时权益
            </a-select-option>
            <a-select-option :value="3">
              企业权益
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="form.benefitType === 2" label="临时类型" required prop="dataType">
          <a-radio-group :disabled="isView" v-model="form.dataType">
            <a-radio :value="1">
              图+链接
            </a-radio>
            <a-radio :value="2">
              优惠券
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="benefitTitle" label="权益名称" prop="benefitTitle">
          <a-input
              v-model="form.benefitTitle"
              :disabled="isView"
              @blur="
          () => {
            $refs.benefitTitle.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item ref="benefitSubtitle" v-if="form.benefitType === 1" label="文本标题" prop="benefitSubtitle">
          <a-input
              :disabled="isView"
              v-model="form.benefitSubtitle"
          />
        </a-form-model-item>
        <a-form-model-item v-if="form.benefitType === 1" label="文本副标题">
          <a-input
              :disabled="isView"
              v-model="form.textData"
          />
        </a-form-model-item>
        <a-form-model-item v-if="form.benefitType === 1 || form.benefitType === 2 && form.dataType === 1" label="链接">
          <a-input
              :disabled="isView"
              v-model="form.benefitUrl"
          />
        </a-form-model-item>
        <a-form-model-item ref="activityCouponId" v-show="form.benefitType === 2 && form.dataType === 2"
                           :required="form.benefitType === 2 && form.dataType === 2"
                           label="优惠券活动ID" prop="activityCouponId">
          <a-input
              :disabled="isView"
              v-model="form.activityCouponId"
              @blur="
          () => {
            $refs.activityCouponId.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item label="图片类型" v-if="form.benefitType !== 1">
          <a-select :disabled="isView" @change="imageTypeChange" v-model="form.imageType" placeholder="请选择图片类型">
            <a-select-option :value="1">
              一排一张
            </a-select-option>
            <a-select-option :value="2">
              一排两张
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="权益封面图"  prop="benefitImage">
          <a-upload
              :action="IMG_API + '/oss/files'"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChange"
              :before-upload="beforeUpload"
              :disabled="isView"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          <p style="color: red">注：建议图片尺寸上传 {{ imgSize }}</p>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="所属项目" required prop="projectList">
          <a-select :disabled="isView" mode="multiple" v-model="form.projectList">
            <a-select-option v-for="item in projects" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
              :disabled="isView"
              v-model="form.remark"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button v-if="!isView" type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetForm">
            {{ isView ? '返回' : '取消' }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {
  projectName,
  addEquityManagement,
  findOneById,
  editEquityManagement
} from '@/api/equityManagement';
import {IMG_API} from '@/config';
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      projects: (state) => {
        return state.common.projectList;
      },
    }),
  },
  data() {
    let checkPending;
    let checkActivityCouponId = (rule, value, callback) => {
      clearTimeout(checkPending);
      if (this.form.benefitType === 2 && this.form.dataType === 2) {
        if (!value) {
          return callback(new Error('请输入优惠券活动ID'));
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      // 当前查看或编辑的权益id
      id: '',
      // 是否是查看
      isView: false,
      labelCol: {span: 4},
      wrapperCol: {span: 4},
      other: '',
      IMG_API: IMG_API,
      form: {
        id: '',
        // 权益类型
        benefitType: 1,
        // 临时类型
        dataType: 1,
        // 权益名称
        benefitTitle: '',
        // 权益副标题
        benefitSubtitle: '',
        // 文本名称
        textData: '',
        // 链接
        benefitUrl: '',
        // 优惠券活动ID
        activityCouponId: '',
        // 图片类型
        imageType: 2,
        // 权益封面图
        benefitImage: '',
        // 选中的项目
        projectList: [],
        // 备注
        remark: ''
      },
      previewVisible: false,
      previewImage: '',
      // 图片尺寸
      imgSize: '',
      fileList: [],
      rules: {
        benefitType: [{required: true, message: '请选择权益类型', trigger: 'change'}],
        dataType: [{required: true, message: '请选择临时类型', trigger: 'change'}],
        benefitTitle: [{required: true, message: '请输入权益名称', trigger: 'blur'}],
        activityCouponId: [{ validator: checkActivityCouponId, trigger: 'blur'}],
        projectList: [{required: true, message: '请选择所属项目', trigger: 'change'}],
        benefitSubtitle: [{required: true, message: '请输入文本标题', trigger: 'blur'}],
        benefitImage: [{required: true, message: '请上传权益图片', trigger: 'change'}]
      }
    };
  },
  watch: {
    'form.benefitType': {
      handler(newValue,oldValue) {
        this.checkImgSize()
      },
      immediate: true,
      deep: true
    },
    'form.imageType': {
      handler(newValue,oldValue) {
        this.checkImgSize()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.$route.query.type === 'edit') {
      this.id = this.$route.query.id;
      this.findOne();
    }
    if (this.$route.query.type === 'view') {
      this.id = this.$route.query.id;
      this.isView = true;
      this.findOne();
    }
  },
  methods: {
    checkImgSize () {
      let imgSize = ''
      if (this.form.benefitType === 1) {
        imgSize = '100*100'
      }
      if ((this.form.benefitType === 2 || this.form.benefitType === 3) && this.form.imageType === 1) {
        imgSize = '690*200'
      }
      if ((this.form.benefitType === 2 || this.form.benefitType === 3) && this.form.imageType === 2) {
        imgSize = '335*200'
      }


      this.imgSize = imgSize
    },
    benefitTypeChange(val) {
      this.$refs.ruleForm.clearValidate();
      this.form.benefitType = val

    },
    // 查询一条
    findOne() {
      findOneById(this.id).then(res => {
        if (res.code === '200') {
          this.form.id = res.data.id;
          this.form.benefitTitle = res.data.benefitTitle;
          this.form.benefitType = res.data.benefitType;
          this.form.dataType = res.data.dataType;
          this.form.benefitSubtitle = res.data.benefitSubtitle;
          this.form.textData = res.data.textData;
          this.form.benefitUrl = res.data.benefitUrl;
          if (res.data.benefitImage) {
            this.fileList.push({
              uid: '-1',
              url: res.data.benefitImage
            });
          }
          this.form.activityCouponId = res.data.activityCouponId;
          this.form.imageType = res.data.imageType;
          this.form.benefitImage = res.data.benefitImage;
          this.form.remark = res.data.remark;
          this.form.projectList = res.data.projectList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    onSubmit() {
      console.log(this.form);
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'edit') {
            editEquityManagement(this.form).then(res => {
              if (res.code === '200') {
                this.$router.go(-1);
                this.$message.success('操作成功！');
              } else {
                this.$message.error('操作失败！');
              }
            });
          } else {
            addEquityManagement(this.form).then(res => {
              if (res.code === '200') {
                this.$router.go(-1);
                this.$message.success('操作成功！');
              } else {
                this.$message.error('操作失败！');
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$router.go(-1);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleChange({fileList}) {
      this.fileList = fileList;
      // 删除提交的图片
      if (fileList.length === 0) {
        this.form.benefitImage = '';
        return;
      }
      if (fileList[0].status === 'uploading') {
        this.loading = true;
        return;
      }
      if (fileList[0].status === 'done') {
        this.loading = false;
        this.form.benefitImage = fileList[0].response.redirect_uri;
      }
    },
    imageTypeChange() {
      this.form.benefitImage = ""
      this.fileList = []
    },
    // 图片限制
    beforeUpload(file) {
      let that = this;
      // 校验格式
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/gif';
      if (!isJpgOrPng) {
        this.$message.error('请上传jpg、png格式');
        return Promise.reject();
      }
      // 校验大小
      const imgSize = file.size / 1024 <= 500;
      if (!imgSize) {
        this.$message.error('图片不能超过500KB');
        return Promise.reject();
      }
      return isJpgOrPng && imgSize
    },
    //m,n为正整数的分子和分母
    reductionTo(m, n) {
      let a = m;
      let b = n;
      (a >= b) ? (a = m, b = n) : (a = n, b = m);
      if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
          if (m % i == 0 && n % i == 0) {
            m = m / i;
            n = n / i;
          }
        }
      }
      return m + ':' + n;
    },
  }
};
</script>
